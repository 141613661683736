import React, { useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import { MdClose } from 'react-icons/md';
import { BsTelephoneOutbound } from "react-icons/bs";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Marquee from 'react-fast-marquee';
import Responsive from './helpers/Responsive';
import { Button } from 'react-bootstrap';
import ScrollToTop from 'react-scroll-to-top';
import { ReactComponent as MyImg } from '../Assets/double-arrow-up.svg';
import { Modal } from 'antd';
import Logo from '../Assets/logo.png';
import About1 from '../Assets/about-1.svg';
import About2 from '../Assets/about-2.svg';
import About3 from '../Assets/about-3.svg';
import Content1 from '../Assets/content-1.png';
import Content2 from '../Assets/content-2.png';
import Content3 from '../Assets/content-3.png';
import Content4 from '../Assets/content-4.png';
import Content5 from '../Assets/content-5.png';
import Content6 from '../Assets/content-6.png';
import Content7 from '../Assets/content-7.png';
import Content8 from '../Assets/content-8.png';
import Collaboration from '../Assets/collaboration.png';
import PengenKopiLogo from '../Assets/pengen-kopi-logo.png';
import PorosKopiLogo from '../Assets/poros-kopi-logo.png';
import SCAILogo from '../Assets/scai-logo.png';
import EmpoLogo from '../Assets/empo-logo.png';
import PengenKopiLogoMobile from '../Assets/pengen-kopi-logo-mobile.png';
import PorosKopiLogoMobile from '../Assets/poros-kopi-logo-mobile.png';
import SCAILogoMobile from '../Assets/scai-logo-mobile.png';
import EmpoLogoMobile from '../Assets/empo-logo-mobile.png';
import EmporiumMall from '../Assets/emporium-mall.png';
import Train from '../Assets/train.png';
import Transjakarta from '../Assets/transjakarta.png';
import Event1 from '../Assets/event-1.png';
import Event2 from '../Assets/event-2.png';
import Event3 from '../Assets/event-3.png';
import Event4 from '../Assets/event-4.png';
import Event5 from '../Assets/event-5.png';
import Blink from '../Assets/blink.svg';
import PengenKopi from '../Assets/tenant/pengen-kopi.png';
import Wacaco from '../Assets/tenant/wacaco.png';
import BantengMas from '../Assets/tenant/banteng-mas.png';
import Barista from '../Assets/tenant/barista.png';
import Brewista from '../Assets/tenant/brewista.png';
import ICA from '../Assets/tenant/ica.png';
import Felicita from '../Assets/tenant/felicita.png';
import TWW from '../Assets/tenant/tww.png';
import Talasi from '../Assets/tenant/talasi.png';
import LaPavoni from '../Assets/tenant/la-pavoni.png';
import Lelit from '../Assets/tenant/lelit.png';
import KitchenArt from '../Assets/tenant/kitchenart.png';
import KitchenAid from '../Assets/tenant/kitchenaid.png';
import Santoker from '../Assets/tenant/santoker.png';
import Bomber from '../Assets/tenant/bomber.png';
import MakmurJaya from '../Assets/tenant/makmur-jaya.png';
import Djauw from '../Assets/tenant/djauw.png';
import Gaggia from '../Assets/tenant/gaggia.png';
import FIftyFive from '../Assets/tenant/fifty-five.png';
import KinGrinder from '../Assets/tenant/kin-grinder.png';
import Outin from '../Assets/tenant/outin.png';
import Ceado from '../Assets/tenant/ceado.png';
import Miir from '../Assets/tenant/miir.png';
import Nucleus from '../Assets/tenant/nucleus.png';
import Kaybee from '../Assets/tenant/kaybee.png';
import CLogo from '../Assets/tenant/c.png';
import Delizia from '../Assets/tenant/delizia.png';
import Ubrukopi from '../Assets/tenant/ubrukopi.png';
import Rasa from '../Assets/tenant/rasa.png';
import Fore from '../Assets/tenant/fore.png';
import Robeanhood from '../Assets/tenant/robeanhood.png';
import UKM from '../Assets/tenant/ukm.png';
import Anomali from '../Assets/tenant/anomali.png';
import Tadaima from '../Assets/tenant/tadaima.png';
import Tuku from '../Assets/tenant/tuku.png';
import Cafilas from '../Assets/tenant/cafilas.png';
import BH from '../Assets/tenant/bh.png';
import DiBawahTangga from '../Assets/tenant/dibawah-tangga.png';
import Eureka from '../Assets/tenant/eureka.png';
import Slide1 from '../Assets/slide-1.png';
import Slide2 from '../Assets/slide-2.png';
import Slide3 from '../Assets/slide-3.png';
import Slide4 from '../Assets/slide-4.png';
import Slide5 from '../Assets/slide-5.png';
import Maps from '../Assets/maps.png';
import Tenant1 from '../Assets/tenant-booth/tenant-1.JPG';
import Tenant2 from '../Assets/tenant-booth/tenant-2.JPG';
import Tenant3 from '../Assets/tenant-booth/tenant-3.JPG';
import Tenant4 from '../Assets/tenant-booth/tenant-4.JPG';
import Tenant5 from '../Assets/tenant-booth/tenant-5.JPG';
import Tenant7 from '../Assets/tenant-booth/tenant-7.JPG';
import Tenant8 from '../Assets/tenant-booth/tenant-8.JPG';
import Tenant9 from '../Assets/tenant-booth/tenant-9.JPG';
import Tenant10 from '../Assets/tenant-booth/tenant-10.JPG';
import Tenant11 from '../Assets/tenant-booth/tenant-11.JPG';
import Tenant12 from '../Assets/tenant-booth/tenant-12.JPG';
import Tenant13 from '../Assets/tenant-booth/tenant-13.JPG';
import Tenant14 from '../Assets/tenant-booth/tenant-14.JPG';
import Tenant15 from '../Assets/tenant-booth/tenant-15.JPG';
import Tenant16 from '../Assets/tenant-booth/tenant-16.JPG';
import Tenant17 from '../Assets/tenant-booth/tenant-17.JPG';
import Tenant18 from '../Assets/tenant-booth/tenant-18.JPG';
import Tenant19 from '../Assets/tenant-booth/tenant-19.JPG';
import Tenant21 from '../Assets/tenant-booth/tenant-21.JPG';
import Tenant22 from '../Assets/tenant-booth/tenant-22.JPG';
import Tenant23 from '../Assets/tenant-booth/tenant-23.JPG';
import Workshop1 from '../Assets/workshop/workshop-1.JPG';
import Workshop2 from '../Assets/workshop/workshop-2.JPG';
import Workshop3 from '../Assets/workshop/workshop-3.JPG';
import Workshop4 from '../Assets/workshop/workshop-4.JPG';
import Workshop5 from '../Assets/workshop/workshop-5.JPG';
import Workshop6 from '../Assets/workshop/workshop-6.JPG';
import Workshop7 from '../Assets/workshop/workshop-7.JPG';
import Workshop8 from '../Assets/workshop/workshop-8.JPG';
import Workshop9 from '../Assets/workshop/workshop-9.JPG';
import Workshop10 from '../Assets/workshop/workshop-10.JPG';
import Workshop11 from '../Assets/workshop/workshop-11.JPG';
import Workshop12 from '../Assets/workshop/workshop-12.JPG';

const Home = () => {
    const [transportation, setTransportation] = useState('car');
    const [day, setDay] = useState(1);
    const [slide, setSlide] = useState(0);
    const [viewMore, setViewMore] = useState(false);
    const [message, setMessage] = useState('');
    const [hoverMaps, setHoverMaps] = useState(false);
    const [gallery, setGallery] = useState('all');
    const [showGallery, setShowGallery] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [zoomImage, setZoomImage] = useState(null);

    const home = useRef(null);
    const whatsOn = useRef(null);
    const whoThere = useRef(null);
    const gettingHere = useRef(null);
    const rundown = useRef(null);
    const contact = useRef(null);
    const timerRef = useRef(null)
    const galleryRef = useRef(null)

    useEffect(() => {
        if(timerRef.current){
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (slide !== 4) {
                setSlide(c=>c+1);
            } else {
                setSlide(0);
            }
        }, 15000);
        return ()=>clearTimeout(timerRef.current);
    }, [slide]);
    
    const handleClick = (value) => {
        value === 'home' && home.current?.scrollIntoView({behavior: 'smooth'});
        value === 'whatsOn' && whatsOn.current?.scrollIntoView({behavior: 'smooth'});
        value === 'whoThere' && whoThere.current?.scrollIntoView({behavior: 'smooth'});
        value === 'gettingHere' && gettingHere.current?.scrollIntoView({behavior: 'smooth'});
        value === 'rundown' && rundown.current?.scrollIntoView({behavior: 'smooth'});
        value === 'contact' && contact.current?.scrollIntoView({behavior: 'smooth'});
        value === 'gallery' && galleryRef.current?.scrollIntoView({behavior: 'smooth'});
        setShowGallery(false);
    };

    const handleZoomImage = (value) => {
        setOpenModal(true);
        setZoomImage(value);
    }

    return (
    <div>
        <Navbar handleClick={handleClick} />
        <ScrollToTop smooth viewBox='0 0 24 24' style={{ backgroundColor: '#4C6444', width: 80, height: 80, borderRadius: 12 }} component={<MyImg style={{ color: '#FFFFFF' }} width={60} height={60} />} color='white' />
        <Responsive desktop={
            <>
                <style>{`
                    .ant-modal-content {
                        padding: 0 !important
                    }
                `}</style>
            {!showGallery ? (
                <>
                    <div ref={home} style={{ display: 'flex' }}>
                        <div style={{ color: '#CBBA9D', backgroundColor: '#203731', width: '50%', padding: '5rem 4rem' }}>
                            <p className='font-bold' style={{ fontSize: 56 }}>Brewing Indonesia's Finest</p>
                            <div style={{ fontSize: 24, padding: '3rem 0' }}>
                                <p>Experience the culmination of Indonesian coffee culture at</p>
                                <p className='font-bold' style={{ marginBottom: 0 }}>Emporium Pluit Mall, Jakarta. July 31st - August 4th, 2024</p>
                            </div>
                            <p style={{ fontSize: 20, width: '90%', marginBottom: '3rem' }}>
                                The Indonesia Cafe Show, themed 'Brewing Indonesia's Finest,' is the premier event celebrating the rich heritage and exceptional coffee of Indonesia. This inaugural event brings together industry professionals, cafe owners, and coffee enthusiasts for a unique experience.
                            </p>
                            <div onClick={() => handleClick('contact')} style={{ display: 'flex', backgroundColor: '#EBEBEB', color: '#2B2225', border: '2px solid #2B2225', width: 'fit-content', cursor: 'pointer', boxShadow: '5px 5px #EBEBEB' }}>
                                <p className='font-bold cursor-pointer' style={{ borderRight: '2px solid #2B2225', padding: 7, alignItems: 'center', marginBottom: 0 }}>Contact Us</p><BsTelephoneOutbound style={{ margin: 10 }} />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className='d-flex align-items-center' style={{ position: 'absolute', marginTop: '38rem', marginLeft: '19rem' }}>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 0 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 1 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 2 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 3 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 4 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mt-5'>
                                    <FaArrowLeft onClick={() => setSlide(slide === 0 ? 4 : slide - 1)} className='p-1 mr-3' style={{ backgroundColor: '#FFFFFF', color: '#000000', borderRadius: 12, width: 40, height: 28, cursor: 'pointer' }} />
                                    <FaArrowRight onClick={() => setSlide(slide === 4 ? 0 : slide + 1)} className='p-1' style={{ backgroundColor: '#9F6B46', color: '#FFFFFF', borderRadius: 12, width: 40, height: 28, cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div style={{ width: 960, height: 810 }}>
                                {slide === 0 &&
                                    <img src={Slide1} />
                                }
                                {slide === 1 &&
                                    <img src={Slide2} />
                                }
                                {slide === 2 &&
                                    <img src={Slide3} />
                                }
                                {slide === 3 &&
                                    <img src={Slide4} />
                                }
                                {slide === 4 &&
                                    <img src={Slide5} />
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', color: '#2B2225', backgroundColor: '#8A966D', fontSize: 20 }}>
                        <Marquee>
                            <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>INDONESIA CAFE SHOW</p>
                            <img src={Blink} />
                            <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>BREWING INDONESIA'S FINEST</p>
                            <img src={Blink} />
                            <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>INDONESIA CAFE SHOW</p>
                            <img src={Blink} />
                            <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>BREWING INDONESIA'S FINEST</p>
                            <img src={Blink} />
                            <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>INDONESIA CAFE SHOW</p>
                            <img src={Blink} />
                            <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>BREWING INDONESIA'S FINEST</p>
                            <img src={Blink} />
                        </Marquee>
                    </div>
                    <div style={{ padding: '4rem 9rem', backgroundColor: '#EBEBEB' }}>
                        <div style={{ display: 'flex', marginBottom: 40, marginLeft: 30 }}>
                            <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                            <p className='font-bold' style={{ fontSize: 48, color: '#102821' }}>About the Show: Brewing Indonesia's Finest</p>
                        </div>
                        <p style={{ fontSize: 20, width: '50%', color: '#102821', lineHeight: 1.8, marginLeft: 30, marginBottom: 20 }}>
                            Brewing Indonesia's Finest isn't just our theme, it's our mission. We're passionate about showcasing the exceptional quality and excellence of Indonesian coffee, from bean to cup. This inaugural show brings together industry professionals, cafe owners, and coffee enthusiasts for a unique experience.
                        </p>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: 50 }}>
                            <div style={{ textAlign: 'center', backgroundColor: '#CBBA9D', borderRadius: 36, flex: 1, justifyContent: 'center', margin: 35, border: '1px solid #102821' }}>
                                <img src={About1} style={{ padding: '3rem 0' }} width={221} height={221} />
                                <p className='font-bold' style={{ color: '#203731', fontSize: 26, marginBottom: 30 }}>Discover diverse flavors</p>
                                <p style={{ color: '#102821', fontSize: 20, marginBottom: 30, padding: '0 2rem' }}>Experience a diverse selection of coffees sourced from different regions across Indonesia.</p>
                            </div>
                            <div style={{ textAlign: 'center', backgroundColor: '#CBBA9D', borderRadius: 36, flex: 1, justifyContent: 'center', margin: 35, border: '1px solid #102821' }}>
                                <img src={About2} style={{ padding: '3rem 0' }} width={257} height={221} />
                                <p className='font-bold' style={{ color: '#203731', fontSize: 26, marginBottom: 30 }}>National brewing competition</p>
                                <p style={{ color: '#102821', fontSize: 20, marginBottom: 30, padding: '0 2rem' }}>Witness the expertise of baristas at The Indonesia Brewers Competition & BrewTanding Tiga by AKSI SCAI</p>
                            </div>
                            <div style={{ textAlign: 'center', backgroundColor: '#CBBA9D', borderRadius: 36, flex: 1, justifyContent: 'center', margin: 35, border: '1px solid #102821' }}>
                                <img src={About3} style={{ padding: '3rem 0' }} width={221} height={221} />
                                <p className='font-bold' style={{ color: '#203731', fontSize: 26, marginBottom: 30 }}>Connect with the community</p>
                                <p style={{ color: '#102821', fontSize: 20, marginBottom: 30, padding: '0 2rem' }}>Join a community of passionate coffee lovers, industry professionals, and potential business collaborators.</p>
                            </div>
                        </div>
                        <div ref={whatsOn} style={{ display: 'flex', marginLeft: 30, marginBottom: 40 }}>
                            <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                            <p className='font-bold' style={{ fontSize: 48, color: '#102821', marginBottom: 0 }}>What's On</p>
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: 40 }}>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content1} width={349} height={450} />
                            </div>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content2} width={349} height={450} />
                            </div>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content3} width={349} height={450} />
                            </div>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content4} width={349} height={450} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: '7rem' }}>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content5} width={349} height={450} />
                            </div>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content6} width={349} height={450} />
                            </div>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content7} width={349} height={450} />
                            </div>
                            <div style={{ flex: 1, justifyContent: 'center', margin: '0 30px' }}>
                                <img src={Content8} width={349} height={450} />
                            </div>
                        </div>
                    </div>
                    <div ref={whoThere} style={{ backgroundColor: '#4C6444', border: '1px solid #2B2225', display: 'flex' }}>
                        <div style={{ width: '50%' }}>
                            <img src={Collaboration} width={700} height={700} style={{ marginTop: -75, marginLeft: 40 }} />
                        </div>
                        <div style={{ width: '50%', backgroundColor: '#203731', border: '2px solid #2B2225', padding: '1.5rem 0 1.5rem 1.5rem', borderRadius: '44px 0 0 44px' }}>
                            <div style={{ backgroundColor: '#EBEBEB', borderRadius: '44px 0 0 44px', padding: '1rem 7rem' }}>
                                <div style={{ display: 'flex', marginBottom: '-30px' }}>
                                    <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                                    <p className='font-bold' style={{ fontSize: 48, color: '#2B2225', margin: 0 }}>Brewing Up</p>
                                </div>
                                <p className='font-bold' style={{ fontSize: 48, color: '#2B2225', marginLeft: 50, marginBottom: 50 }}>a Perfect Collaboration!</p>
                                <div style={{ fontSize: 20, color: '#2B2225' }}>
                                    <p style={{ lineHeight: 1.5, marginBottom: '2rem' }}>
                                        We're proud to announce our collaboration with industry leaders such as <span className='font-bold'>SCAI (Specialty Coffee Association of Indonesia), Poros Kopi, Emporium Pluit Mall,</span> and <span className='font-bold'>PengenKopi.</span>
                                    </p>
                                    <p style={{ lineHeight: 1.5, marginBottom: '2rem' }}>
                                        Join us for an unforgettable experience celebrating the finest in Indonesian coffee. This event goes beyond a cafe show – it's a vibrant celebration of Indonesian coffee culture.
                                    </p>
                                    <p style={{ lineHeight: 1.5, marginBottom: '2rem' }}>
                                        Seize the opportunity to learn, connect, and explore the latest coffee equipment, while experiencing the best that Indonesia has to offer!
                                    </p>
                                </div>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <img src={PengenKopiLogo} style={{ marginRight: 30 }} />
                                    <img src={PorosKopiLogo} style={{ marginRight: 30 }} />
                                    <img src={SCAILogo} style={{ marginRight: 30 }} />
                                    <img src={EmpoLogo} style={{ marginRight: 30 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#EBEBEB', padding: '0 9rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '5rem 0' }}>
                            <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                            <p className='font-bold' style={{ fontSize: 48, color: '#102821' }}>Brand Participants</p>
                        </div>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={PengenKopi} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Wacaco} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={BantengMas} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Barista} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Brewista} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={ICA} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Felicita} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={TWW} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Talasi} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Nucleus} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={LaPavoni} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Lelit} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={KitchenArt} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={KitchenAid} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Santoker} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={Bomber} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={MakmurJaya} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Djauw} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Gaggia} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={FIftyFive} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={KinGrinder} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Outin} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Ceado} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Miir} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={BH} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={Kaybee} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={CLogo} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Delizia} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Ubrukopi} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Rasa} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3rem' }}>
                                <img src={Fore} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Robeanhood} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Eureka} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={UKM} width={236} height={75} style={{ marginRight: '4rem'}} />
                                <img src={Anomali} width={236} height={75} style={{ marginRight: '4rem'}} />
                            </div>
                            <div className='row'>
                                <div className='col-12 mb-5'>
                                    <img className='col-3' src={Tadaima} width={236} height={75} />
                                    <img className='col-3' src={Tuku} width={236} height={75} />
                                    <img className='col-3' src={Cafilas} width={236} height={75} />
                                    <img className='col-3' src={DiBawahTangga} width={236} height={75} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={gettingHere}>
                        <div style={{ width: '55%', position: 'absolute', padding: '1.5rem 0 1.5rem 1.5rem' }}>
                            <div style={{ backgroundColor: '#FFFFFF', borderRadius: 36, padding: '3rem 10rem' }}>
                                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                                    <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                                    <p className='font-bold' style={{ fontSize: 48, color: '#2B2225' }}>GETTING HERE</p>
                                </div>
                                <p style={{ fontSize: 24, color: '#242424', marginBottom: 0 }}>
                                    Embark on an unforgettable coffee adventure at Indonesia Caffe Show, held at the vibrant Emporium Pluit Mall. Conveniently located amidst all the conveniences of downtown Jakarta, the event is easily accessible by various transportation options.Plan your Indonesia Caffe Show visit with our suggested travel routes. Explore our interactive map and discover the recommended travel options that best suit you.
                                </p>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ backgroundColor: '#4C6444', width: '50%' }}></div>
                            <div style={{ width: '50%' }}>
                                <img src={EmporiumMall} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', color: '#2B2225', backgroundColor: '#8A966D', fontSize: 20, paddingLeft: '9rem' }}>
                        <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'car' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'car' && '2px solid #2B2225' }} onClick={() => setTransportation('car')}>
                                By Car
                            </p>
                        </div>
                        <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'taxi' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'taxi' && '2px solid #2B2225' }} onClick={() => setTransportation('taxi')}>
                                By Taxi
                            </p>
                        </div>
                        <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'transjakarta' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'transjakarta' && '2px solid #2B2225' }} onClick={() => setTransportation('transjakarta')}>
                                By Transjakarta
                            </p>
                        </div>
                        <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'train' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'train' && '2px solid #2B2225' }} onClick={() => setTransportation('train')}>
                                By Train
                            </p>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#EBEBEB' }}>
                        {(transportation === 'taxi' || transportation === 'car') &&
                            <div className='w-100' style={{ padding: '2rem 9rem' }}>
                                <div className='d-flex justify-content-center align-items-center' style={{ position: 'absolute', backgroundColor: hoverMaps && '#00000080', width: 1504, height: 850, borderRadius: 36 }}
                                    onMouseEnter={() => setHoverMaps(true)}
                                    onMouseLeave={() => setHoverMaps(false)}>
                                    {hoverMaps &&
                                        <Button href='https://maps.app.goo.gl/MY8RdGYsKaGa2qRL6' style={{ backgroundColor: '#4C6444', borderRadius: 12, border: '1px solid #4C6444', fontSize: 24 }}>Map Direction</Button>
                                    }
                                </div>
                                <img src={Maps} />
                            </div>
                        }
                        {transportation === 'transjakarta' &&
                            <div style={{ padding: '2rem 0 3rem 9rem' }}>
                                <img src={Transjakarta} />
                            </div>
                        }
                        {transportation === 'train' &&
                            <div style={{ padding: '2rem 0 3rem 10rem' }}>
                                <img src={Train} />
                            </div>
                        }
                    </div>
                    <div style={{ backgroundColor: '#4C6444' }} ref={rundown}>
                        <div style={{ backgroundColor: '#242424', width: '45%', padding: '0 1rem 1rem 0', borderBottomRightRadius: 36 }}>
                            <div style={{ display: 'flex', backgroundColor: '#EBEBEB', padding: '3rem 9rem', borderBottomRightRadius: 36, borderTop: '1px solid #242424' }}>
                                <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                                <p className='font-bold' style={{ fontSize: 46, color: '#2B2225' }}>RUNDOWN EVENT</p>
                            </div>
                        </div>
                        <div style={{ padding: '3rem 0' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #24242480', margin: '0 21.5rem', fontSize: 24 }}>
                                <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center', borderBottom: day === 1 && '2px solid #FFFFFF' }}>
                                    <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 1 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(1)}>
                                        31 July
                                    </p>
                                </div>
                                <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center', borderBottom: day === 2 && '2px solid #FFFFFF' }}>
                                    <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 2 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(2)}>
                                        01 August
                                    </p>
                                </div>
                                <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center', borderBottom: day === 3 && '2px solid #FFFFFF' }}>
                                    <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 3 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(3)}>
                                        02 August
                                    </p>
                                </div>
                                <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center', borderBottom: day === 4 && '2px solid #FFFFFF' }}>
                                    <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 4 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(4)}>
                                        03 August
                                    </p>
                                </div>
                                <div style={{ padding: '0rem 2rem', display: 'flex', alignItems: 'center', borderBottom: day === 5 && '2px solid #FFFFFF' }}>
                                    <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 5 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(5)}>
                                        04 August
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', height: 1115, padding: '0 10rem' }}>
                                {day === 1 &&
                                    <>
                                        <div style={{ color: '#EBEBEB', fontSize: 40, marginRight: '29rem' }}>
                                            <p className='font-bold'>Day 1</p>
                                            <p className='font-bold' style={{ display: 'flex' }}>Wednesday 31<span className='font-bold' style={{ fontSize: 14, marginTop: '0.5rem' }}>TH</span></p>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        10.00 - 12.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>COMPETITION</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Brewtanding 3</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        13.00 - 20.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>COMPETITION</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Brewtanding 3</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        20.00 - 22.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}></p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>TBA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {day === 2 &&
                                    <>
                                        <div style={{ color: '#EBEBEB', fontSize: 40, marginRight: '29rem' }}>
                                            <p className='font-bold'>Day 2</p>
                                            <p className='font-bold' style={{ display: 'flex' }}>Thursday 01<span className='font-bold' style={{ fontSize: 14, marginTop: '0.5rem' }}>st</span></p>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        10.00 - 14.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>WORKSHOP</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Brewers Cup Judge Calibration in Class</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        14.00 - 18.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>WORKSHOP</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Brewers Cup Judge Calibration on Stage</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        18.00 - 19.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>EXPERIENCE</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>UbruKopi Session on stage</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        19.00 - 22.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}></p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>TBA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {day === 3 &&
                                    <>
                                        <div style={{ color: '#EBEBEB', fontSize: 40, marginRight: '29rem' }}>
                                            <p className='font-bold'>Day 3</p>
                                            <p className='font-bold' style={{ display: 'flex' }}>Friday 02<span className='font-bold' style={{ fontSize: 14, marginTop: '0.5rem' }}>ND</span></p>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        10.00 - 18.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>COMPETITION</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>UbruKopi Brewers Cup Preliminaries Day 1</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        18.00 - 19.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>EXPERIENCE</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Ryan Wibawa Session on Stage</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        19.00 - 20.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>WORKSHOP</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Latter art Workshop by Indonesia Coffee Academy</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        20.00 - 22.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}></p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>TBA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {day === 4 &&
                                    <>
                                        <div style={{ color: '#EBEBEB', fontSize: 40, marginRight: '29rem' }}>
                                            <p className='font-bold'>Day 4</p>
                                            <p className='font-bold' style={{ display: 'flex' }}>Saturday 03<span className='font-bold' style={{ fontSize: 14, marginTop: '0.5rem' }}>RD</span></p>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        10.00 - 16.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>COMPETITION</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Uburkopi Brewers Cup Preliminiaries Day 2</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        16.00 - 17.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>EXPERIENCE</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Uburkopi session on stage</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        17.00 - 19.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}></p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>TBA</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        19.00 - 20.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>WORKSHOP</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Sensory Workshop by indonesia coffee academy</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        20.00 - 22.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}></p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>TBA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {day === 5 &&
                                    <>
                                        <div style={{ color: '#EBEBEB', fontSize: 40, marginRight: '29rem' }}>
                                            <p className='font-bold'>Day 5</p>
                                            <p className='font-bold' style={{ display: 'flex' }}>Sunday 04<span className='font-bold' style={{ fontSize: 14, marginTop: '0.5rem' }}>TH</span></p>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        10.00 - 17.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>COMPETITION</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Uburkopi Brewers Cup Finals</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        17.00 - 19.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>COMPETITION</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Uburkopi Brewers Cup Winner Announcement</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        19.00 - 20.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}>EXPERIENCE</p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>Coffe Cupping by Indonesia Coffee Academy</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                                <div style={{ paddingTop: '1rem'}}>
                                                    <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 16 }}>
                                                        20.00 - 22.00 WIB
                                                    </p>
                                                </div>
                                                <div style={{ color: '#242424', width: 400 }}>
                                                    <p style={{ margin: 0, fontSize: 16 }}></p>
                                                    <p className='font-bold' style={{ margin: 0, fontSize: 16 }}>TBA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            {day === 1 &&
                                <img src={Event1} width={1020} height={118} />
                            }
                            {day === 2 &&
                                <img src={Event2} width={1020} height={118} />
                            }
                            {day === 3 &&
                                <img src={Event3} width={1020} height={118} />
                            }
                            {day === 4 &&
                                <img src={Event4} width={1020} height={118} />
                            }
                            {day === 5 &&
                                <img src={Event5} width={1020} height={118} />
                            }
                        </div>
                    </div>
                    <div ref={galleryRef} style={{ margin: '4rem 0' }}>
                        <div className='justify-content-center' style={{ display: 'flex', marginBottom: '2rem' }}>
                            <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                            <p className='font-bold' style={{ fontSize: 48, color: '#2B2225' }}>GALLERY</p>
                        </div>
                        <div className='row' style={{ padding: '0 9rem'}}>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant1} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant2} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant3} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant4} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant5} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant7} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant8} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant9} />
                            </div>
                            <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                <img src={Tenant10} />
                            </div>
                        </div>
                        <div className='text-center'>
                            <span onClick={() => setShowGallery(true)} className='font-bold' style={{ color: '#2B2225', fontSize: 36, cursor: 'pointer' }}>View More</span>
                        </div>
                    </div>
                    <div ref={contact} style={{ padding: '3rem 0 3rem 9rem', display: 'flex', backgroundColor: '#EBEBEB' }}>
                        <div style={{ backgroundColor: '#4C6444', color: '#FFFFFF', width: '30%', padding: '2rem 3rem 2rem 2rem', borderRadius: 36, fontSize: 24 }}>
                            <p className='font-bold' style={{ fontSize: 40 }}>Get In Touch</p>
                            <p>Visit Us:</p>
                            <p>Grand Slipi Tower Lt 10 MNO</p>
                            <p>Jl. S Parman kav 22-24 Palmerah</p>
                            <p style={{ marginBottom: '4rem' }}>Jakarta Barat DKI Jakarta 11480</p>
                            <p>WhatsApp Chat:</p>
                            <p>Mon - Fri from 08.00 to 20.00 WIB</p>
                            <p style={{ marginBottom: '4rem' }}>+6289507518628</p>
                            <p>Email:</p>
                            <p style={{ marginBottom: '4rem' }}>marketing@pengenkopi.com</p>
                            <p>Social Media:</p>
                            <p>@indonesiacafeshow</p>
                        </div>
                        <div style={{ padding: '0 12rem', width: '70%' }}>
                            <div style={{ display: 'flex', marginBottom: '2rem' }}>
                                <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                                <p className='font-bold' style={{ fontSize: 48, color: '#2B2225' }}>Contact Us</p>
                            </div>
                            <p className='font-bold' style={{ fontSize: 40, color: '#4C6444' }}>Have Questions? We're Here to Help</p>
                            <p style={{ fontSize: 24, color: '#242424', marginBottom: '2rem' }}>Whether you need assistance with registration, logistics, or general inquiries, our dedicated team is eager to assist you. Reach out to us and let's make your Indonesia Caffe Show experience a seamless one.</p>
                            <div style={{ marginBottom: '2rem' }}>
                                <input type='text' name='fullName' placeholder='Full Name' className='form-control' style={{ border: '1px solid #4C6444', borderRadius: 12, backgroundColor: '#EBEBEB' }} />
                            </div>
                            <div style={{ marginBottom: '2rem' }}>
                                <input type='text' name='PhoneNumber' placeholder='Whatsapp Number' className='form-control' style={{ border: '1px solid #4C6444', borderRadius: 12, backgroundColor: '#EBEBEB' }} />
                            </div>
                            <div>
                                <textarea name='message' placeholder='Tell us what you need' onChange={(e) => setMessage(e.target.value)} className='form-control' rows={10} style={{ border: '1px solid #4C6444', borderRadius: 12, backgroundColor: '#EBEBEB' }} />
                            </div>
                            <Button rel="noopener noreferrer" target='_blank' href={`https://api.whatsapp.com/send/?phone=6289507518628&text=${message}`} className='mt-4 p-2 text-white font-weight-bold' style={{ float: 'right', backgroundColor: '#4C6444', borderRadius: 8, border: '1px solid #4C6444' }}>
                                Contact Us
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                <div style={{ padding: '4rem 11rem' }}>
                    <div className='justify-content-center mb-5' style={{ display: 'flex' }}>
                        <hr style={{ backgroundColor: '#102821', width: 10, height: 70, borderRadius: 10, marginRight: 40, marginTop: 0 }} />
                        <p className='font-bold' style={{ fontSize: 48, color: '#2B2225' }}>GALLERY</p>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <hr className='w-100' style={{ border: '2px solid #242424', marginTop: '2rem' }} />
                        <div className='d-flex' style={{ backgroundColor: '#242424', color: '#FFFFFF', width: 'fit-content', borderRadius: 16, position: 'absolute', fontSize: 20 }}>
                            <p className='mx-3 my-2 font-bold' style={{ backgroundColor: gallery === 'all' && '#4C6444', borderRadius: 8, padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={() => setGallery('all')}>All Gallery</p>
                            <p className='mx-3 my-2 font-bold' style={{ backgroundColor: gallery === 'workshop' && '#4C6444', borderRadius: 8, padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={() => setGallery('workshop')}>Workshop</p>
                            <p className='mx-3 my-2 font-bold' style={{ backgroundColor: gallery === 'tenant' && '#4C6444', borderRadius: 8, padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={() => setGallery('tenant')}>Tenant</p>
                        </div>
                    </div>
                    <div className='row mt-5 pt-5'>
                        {gallery !== 'workshop' &&
                            <>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant1} alt='gallery' onClick={() => handleZoomImage(Tenant1)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant2} alt='gallery' onClick={() => handleZoomImage(Tenant2)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant3} alt='gallery' onClick={() => handleZoomImage(Tenant3)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant4} alt='gallery' onClick={() => handleZoomImage(Tenant4)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant5} alt='gallery' onClick={() => handleZoomImage(Tenant5)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant7} alt='gallery' onClick={() => handleZoomImage(Tenant7)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant8} alt='gallery' onClick={() => handleZoomImage(Tenant8)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant9} alt='gallery' onClick={() => handleZoomImage(Tenant9)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant10} alt='gallery' onClick={() => handleZoomImage(Tenant10)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant11} alt='gallery' onClick={() => handleZoomImage(Tenant11)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant12} alt='gallery' onClick={() => handleZoomImage(Tenant12)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant13} alt='gallery' onClick={() => handleZoomImage(Tenant13)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant14} alt='gallery' onClick={() => handleZoomImage(Tenant14)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant15} alt='gallery' onClick={() => handleZoomImage(Tenant15)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant16} alt='gallery' onClick={() => handleZoomImage(Tenant16)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant17} alt='gallery' onClick={() => handleZoomImage(Tenant17)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant18} alt='gallery' onClick={() => handleZoomImage(Tenant18)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant19} alt='gallery' onClick={() => handleZoomImage(Tenant19)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant21} alt='gallery' onClick={() => handleZoomImage(Tenant21)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant22} alt='gallery' onClick={() => handleZoomImage(Tenant22)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Tenant23} alt='gallery' onClick={() => handleZoomImage(Tenant23)} style={{ cursor: 'pointer' }} />
                                </div>
                            </>
                        }
                        {gallery !== 'tenant' &&
                            <>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop1} alt='gallery' onClick={() => handleZoomImage(Workshop1)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop2} alt='gallery' onClick={() => handleZoomImage(Workshop2)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop3} alt='gallery' onClick={() => handleZoomImage(Workshop3)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop4} alt='gallery' onClick={() => handleZoomImage(Workshop4)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop5} alt='gallery' onClick={() => handleZoomImage(Workshop5)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop6} alt='gallery' onClick={() => handleZoomImage(Workshop6)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop7} alt='gallery' onClick={() => handleZoomImage(Workshop7)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop8} alt='gallery' onClick={() => handleZoomImage(Workshop8)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop9} alt='gallery' onClick={() => handleZoomImage(Workshop9)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop10} alt='gallery' onClick={() => handleZoomImage(Workshop10)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop11} alt='gallery' onClick={() => handleZoomImage(Workshop11)} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='col-4 mb-4' style={{ padding: '0 0.75rem'}}>
                                    <img src={Workshop12} alt='gallery' onClick={() => handleZoomImage(Workshop12)} style={{ cursor: 'pointer' }} />
                                </div>
                            </>
                        }
                    </div>
                </div>
                <Modal width={1000} centered open={openModal} footer={false} closable={false}>
                    <MdClose onClick={() => setOpenModal(false)} style={{ backgroundColor: '#EBEBEB', position: 'absolute', width: 42, height: 42, borderRadius: 50, margin: '-50px 0 0 -120px', cursor: 'pointer', padding: 10 }} />
                    <img src={zoomImage} alt='gallery' />
                </Modal>
                </>
            )}
                <div style={{ backgroundColor: '#CBBA9D', textAlign: 'center' }}>
                    <div style={{ padding: '4rem 0 2rem 0' }}>
                        <img src={Logo} />
                    </div>
                    <p className='font-bold'>In Collaboration With:</p>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding: '2rem 0 4rem 0' }}>
                        <div style={{ marginRight: '2rem' }}>
                            <img src={PengenKopiLogo} />
                        </div>
                        <div style={{ marginRight: '2rem' }}>
                            <img src={SCAILogo} />
                        </div>
                        <div style={{ marginRight: '2rem' }}>
                            <img src={PorosKopiLogo} />
                        </div>
                        <div style={{ marginRight: '2rem' }}>
                            <img src={EmpoLogo} />
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: '#EBEBEB', display: 'flex', justifyContent: 'space-between', padding: '1rem 9rem' }}>
                    <div>
                        © 2023 PengenKopi All Rights Reserved
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '2rem' }}>HOME</div>
                        <div style={{ marginRight: '2rem' }}>WHAT'S ON</div>
                        <div style={{ marginRight: '2rem' }}>WHO'S THERE</div>
                        <div style={{ marginRight: '2rem' }}>GETTING HERE</div>
                        <div style={{ marginRight: '2rem' }}>MORE</div>
                    </div>
                </div>
            </>
        } mobile={
            <>
                <style>{`
                    .maps {
                        height: 242px;
                    }
                    .ant-modal-content {
                        padding: 0 !important
                    }
                    @media (max-width: 414px) {
                        .maps {
                            height: 234px;
                        }
                    }
                    @media (max-width: 390px) {
                        .maps {
                            height: 220px;
                        }
                    }
                    @media (max-width: 375px) {
                        .maps {
                            height: 212px;
                        }
                    }
                    @media (max-width: 360px) {
                        .maps {
                            height: 203px;
                        }
                    }
                `}</style>
                {!showGallery ? (
                    <>
                        <div ref={home} style={{ width: '100%' }}>
                            <div className='d-flex align-items-center' style={{ position: 'absolute', marginTop: '13rem', marginLeft: '2rem' }}>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 0 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 1 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 2 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 3 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mr-4 mt-5 p-1' style={{ border: '1px solid #FFFFFF', width: 20, height: 20, borderRadius: 50 }}>
                                    {slide === 4 &&
                                        <div style={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: 50 }}></div>
                                    }
                                </div>
                                <div className='mt-5 d-flex'>
                                    <FaArrowLeft onClick={() => setSlide(slide === 0 ? 4 : slide - 1)} className='p-1 mr-3' style={{ backgroundColor: '#FFFFFF', color: '#000000', borderRadius: 12, width: 40, height: 28, cursor: 'pointer' }} />
                                    <FaArrowRight onClick={() => setSlide(slide === 4 ? 0 : slide + 1)} className='p-1' style={{ backgroundColor: '#9F6B46', color: '#FFFFFF', borderRadius: 12, width: 40, height: 28, cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div style={{ width: '100%' }}>
                                {slide === 0 &&
                                    <img src={Slide1} />
                                }
                                {slide === 1 &&
                                    <img src={Slide2} />
                                }
                                {slide === 2 &&
                                    <img src={Slide3} />
                                }
                                {slide === 3 &&
                                    <img src={Slide4} />
                                }
                                {slide === 4 &&
                                    <img src={Slide5} />
                                }
                            </div>
                        </div>
                        <div style={{ color: '#CBBA9D', backgroundColor: '#203731', width: '100%', padding: '1rem 1rem' }}>
                            <p className='font-bold' style={{ fontSize: 26 }}>Brewing Indonesia's Finest</p>
                            <div style={{ fontSize: 16 }}>
                                <p>
                                    Experience the culmination of Indonesian coffee culture at <span className='font-bold'>Emporium Pluit Mall, Jakarta. July 31st - August 4th, 2024</span>
                                </p>
                            </div>
                            <p style={{ fontSize: 14, width: '90%', marginBottom: '1rem' }}>
                                The Indonesia Cafe Show, themed 'Brewing Indonesia's Finest,' is the premier event celebrating the rich heritage and exceptional coffee of Indonesia. This inaugural event brings together industry professionals, cafe owners, and coffee enthusiasts for a unique experience.
                            </p>
                            <div onClick={() => handleClick('contact')} className='ml-3 mb-4' style={{ display: 'flex', backgroundColor: '#EBEBEB', color: '#2B2225', border: '2px solid #2B2225', width: 'fit-content', cursor: 'pointer', boxShadow: '5px 5px #EBEBEB' }}>
                                <p className='font-bold' style={{ borderRight: '2px solid #2B2225', padding: 7, alignItems: 'center', marginBottom: 0 }}>Contact Us</p><BsTelephoneOutbound style={{ margin: 10 }} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', color: '#2B2225', backgroundColor: '#8A966D', fontSize: 20 }}>
                            <Marquee>
                                <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>INDONESIA CAFE SHOW</p>
                                <img src={Blink} />
                                <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>BREWING INDONESIA'S FINEST</p>
                                <img src={Blink} />
                                <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>INDONESIA CAFE SHOW</p>
                                <img src={Blink} />
                                <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>BREWING INDONESIA'S FINEST</p>
                                <img src={Blink} />
                                <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>INDONESIA CAFE SHOW</p>
                                <img src={Blink} />
                                <p style={{ padding: '0.5rem 2rem', marginBottom: 0 }}>BREWING INDONESIA'S FINEST</p>
                                <img src={Blink} />
                            </Marquee>
                        </div>
                        <div className='pt-4 pb-5' style={{ backgroundColor: '#EBEBEB' }}>
                            <div className='px-3' style={{ display: 'flex' }}>
                                <hr className='mr-3' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                <p className='font-bold' style={{ fontSize: 24, color: '#102821' }}>About the Show: Brewing</p>
                            </div>
                            <p className='font-bold' style={{ fontSize: 24, color: '#102821', marginTop: '-25px', marginLeft: '2.8rem' }}>Indonesia's Finest</p>
                            <p className='px-3' style={{ fontSize: 16, color: '#102821', lineHeight: 1.8, marginBottom: 20 }}>
                                Brewing Indonesia's Finest isn't just our theme, it's our mission. We're passionate about showcasing the exceptional quality and excellence of Indonesian coffee, from bean to cup. This inaugural show brings together industry professionals, cafe owners, and coffee enthusiasts for a unique experience.
                            </p>
                            <div className='px-3'>
                                <div className='mb-5' style={{ textAlign: 'center', backgroundColor: '#CBBA9D', borderRadius: 36, border: '1px solid #102821' }}>
                                    <img src={About1} style={{ padding: '3rem 0' }} width={221} height={221} />
                                    <p className='font-bold' style={{ color: '#203731', fontSize: 22, marginBottom: 30 }}>Discover diverse flavors</p>
                                    <p style={{ color: '#102821', fontSize: 16, marginBottom: 30, padding: '0 2rem' }}>Experience a diverse selection of coffees sourced from different regions across Indonesia.</p>
                                </div>
                                <div className='mb-5' style={{ textAlign: 'center', backgroundColor: '#CBBA9D', borderRadius: 36, border: '1px solid #102821' }}>
                                    <img src={About2} style={{ padding: '3rem 0' }} width={257} height={221} />
                                    <p className='font-bold' style={{ color: '#203731', fontSize: 22, marginBottom: 30 }}>National brewing competition</p>
                                    <p style={{ color: '#102821', fontSize: 16, marginBottom: 30, padding: '0 2rem' }}>Witness the expertise of baristas at The Indonesia Brewers Competition & BrewTanding Tiga by AKSI SCAI</p>
                                </div>
                                <div className='mb-5' style={{ textAlign: 'center', backgroundColor: '#CBBA9D', borderRadius: 36, border: '1px solid #102821' }}>
                                    <img src={About3} style={{ padding: '3rem 0' }} width={221} height={221} />
                                    <p className='font-bold' style={{ color: '#203731', fontSize: 22, marginBottom: 30 }}>Connect with the community</p>
                                    <p style={{ color: '#102821', fontSize: 16, marginBottom: 30, padding: '0 2rem' }}>Join a community of passionate coffee lovers, industry professionals, and potential business collaborators.</p>
                                </div>
                            </div>
                            <div ref={whatsOn} className='px-3' style={{ display: 'flex', marginBottom: 40 }}>
                                <hr className='mr-3' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                <p className='font-bold' style={{ fontSize: 24, color: '#102821', marginBottom: 0 }}>What's On</p>
                            </div>
                            <div className='row m-0 w-100'>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content1} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content2} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content3} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content4} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content5} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content6} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content7} height={450} />
                                </div>
                                <div className='col-6'>
                                    <img className='mb-4 w-100' src={Content8} height={450} />
                                </div>
                            </div>
                        </div>
                        <div ref={whoThere} style={{ backgroundColor: '#4C6444', border: '1px solid #2B2225' }}>
                            <img className='w-100 pl-3' src={Collaboration} style={{ marginTop: '-35px' }} />
                            <div className='p-3' style={{ backgroundColor: '#203731', border: '2px solid #2B2225' }}>
                                <div style={{ backgroundColor: '#EBEBEB', borderRadius: 36, padding: '1rem 0rem' }}>
                                    <div className='d-flex mt-3'>
                                        <hr className='mx-3' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                        <p className='font-bold ml-1' style={{ fontSize: 24, color: '#2B2225', margin: 0 }}>Brewing Up</p>
                                    </div>
                                    <p className='font-bold ml-5' style={{ fontSize: 24, color: '#2B2225', marginTop: '-30px' }}>a Perfect Collaboration!</p>
                                    <div className='px-3' style={{ fontSize: 16, color: '#2B2225' }}>
                                        <p style={{ lineHeight: 1.5, marginBottom: '1rem' }}>
                                            We're proud to announce our collaboration with industry leaders such as <span className='font-bold'>SCAI (Specialty Coffee Association of Indonesia), Poros Kopi, Emporium Pluit Mall,</span> and <span className='font-bold'>PengenKopi.</span>
                                        </p>
                                        <p style={{ lineHeight: 1.5, marginBottom: '1rem' }}>
                                            Join us for an unforgettable experience celebrating the finest in Indonesian coffee. This event goes beyond a cafe show – it's a vibrant celebration of Indonesian coffee culture.
                                        </p>
                                        <p style={{ lineHeight: 1.5, marginBottom: '1rem' }}>
                                            Seize the opportunity to learn, connect, and explore the latest coffee equipment, while experiencing the best that Indonesia has to offer!
                                        </p>
                                    </div>
                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }} className='px-2'>
                                        <div className='px-2'>
                                            <img src={PengenKopiLogoMobile} />
                                        </div>
                                        <div className='px-2'>
                                            <img src={SCAILogoMobile} />
                                        </div>
                                        <div className='px-2'>
                                            <img src={PorosKopiLogoMobile} />
                                        </div>
                                        <div className='px-2'>
                                            <img src={EmpoLogoMobile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#EBEBEB' }}>
                            <div className='pt-5 pb-4' style={{ display: 'flex', justifyContent: 'center' }}>
                                <hr className='mr-3' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                <p className='font-bold mt-1' style={{ fontSize: 24, color: '#102821' }}>Brand Participants</p>
                            </div>
                            <div className='row m-0 w-100'>
                                <div className='col-6 mb-4'><img src={PengenKopi} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'><img src={Wacaco} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'><img src={BantengMas} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'><img src={Barista} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'> <img src={Brewista} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'><img src={ICA} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'><img src={Felicita} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                <div className='col-6 mb-4'><img src={TWW} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                {viewMore ?
                                    <>
                                        <div className='col-6 mb-4'><img src={Talasi} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={LaPavoni} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Lelit} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={KitchenArt} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={KitchenAid} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Santoker} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Bomber} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={MakmurJaya} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Djauw} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Gaggia} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={FIftyFive} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={KinGrinder} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Outin} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Ceado} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Miir} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Nucleus} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Kaybee} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={CLogo} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Delizia} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Ubrukopi} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Rasa} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Fore} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Robeanhood} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Eureka} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={UKM} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Anomali} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Tadaima} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Tuku} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={Cafilas} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={BH} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <div className='col-6 mb-4'><img src={DiBawahTangga} width={236} height={75} style={{ marginRight: '4rem'}} /></div>
                                        <p className='text-center font-weight-bold w-100' onClick={() => setViewMore(false)} style={{ color: '#2B2225', fontSize: 18 }}>Show Less</p>
                                    </>
                                    :
                                    <p className='text-center font-weight-bold w-100' onClick={() => setViewMore(true)} style={{ color: '#2B2225', fontSize: 18 }}>View More</p>
                                }
                            </div>
                        </div>
                        <div ref={gettingHere}>
                            <div>
                                <img src={EmporiumMall} />
                            </div>
                            <div className='px-3 pb-4' style={{ backgroundColor: '#4C6444', marginTop: '-50px' }}>
                                <div style={{ backgroundColor: '#FFFFFF', borderRadius: 18, position: 'relative' }}>
                                    <div className='d-flex px-3 pt-4'>
                                        <hr className='mr-2' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                        <p className='font-bold' style={{ fontSize: 24, color: '#2B2225' }}>GETTING HERE</p>
                                    </div>
                                    <p className='px-3 pb-3' style={{ fontSize: 16, color: '#242424', marginBottom: 0 }}>
                                        Embark on an unforgettable coffee adventure at Indonesia Caffe Show, held at the vibrant Emporium Pluit Mall. Conveniently located amidst all the conveniences of downtown Jakarta, the event is easily accessible by various transportation options.Plan your Indonesia Caffe Show visit with our suggested travel routes. Explore our interactive map and discover the recommended travel options that best suit you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex px-3' style={{ color: '#2B2225', backgroundColor: '#8A966D', fontSize: 16 }}>
                            <div className='px-2' style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'car' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'car' && '2px solid #2B2225' }} onClick={() => setTransportation('car')}>
                                    By Car
                                </p>
                            </div>
                            <div className='px-2' style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'taxi' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'taxi' && '2px solid #2B2225' }} onClick={() => setTransportation('taxi')}>
                                    By Taxi
                                </p>
                            </div>
                            <div className='px-2' style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'transjakarta' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'transjakarta' && '2px solid #2B2225' }} onClick={() => setTransportation('transjakarta')}>
                                    By Transjakarta
                                </p>
                            </div>
                            <div className='px-2' style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: transportation === 'train' ? '#2B2225' : '#2B222580', borderBottom: transportation === 'train' && '2px solid #2B2225' }} onClick={() => setTransportation('train')}>
                                    By Train
                                </p>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#EBEBEB', overflow: 'auto' }}>
                            {(transportation === 'taxi' || transportation === 'car') &&
                                <>
                                    <div className='w-100 d-flex justify-content-center align-items-center maps' style={{ position: 'absolute', backgroundColor: '#00000080' }}>
                                        <Button href='https://maps.app.goo.gl/MY8RdGYsKaGa2qRL6' style={{ backgroundColor: '#4C6444', borderRadius: 12, border: '1px solid #4C6444' }}>Map Direction</Button>
                                    </div>
                                    <div className='w-100'>
                                        <img src={Maps} />
                                    </div>
                                </>
                            }
                            {transportation === 'transjakarta' &&
                                <div className='p-4' style={{ width: 1100 }}>
                                    <img src={Transjakarta} />
                                </div>
                            }
                            {transportation === 'train' &&
                                <div className='p-4' style={{ width: 1100 }}>
                                    <img src={Train} />
                                </div>
                            }
                        </div>
                        <div ref={rundown} style={{ backgroundColor: '#4C6444' }}>
                            <div style={{ backgroundColor: '#242424', padding: '0 1rem 1rem 0', borderBottomRightRadius: 36, width: '80%' }}>
                                <div className='pt-3' style={{ display: 'flex', backgroundColor: '#EBEBEB', borderBottomRightRadius: 36, borderTop: '1px solid #242424' }}>
                                    <hr className='mx-3' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                    <p className='font-bold' style={{ fontSize: 24, color: '#2B2225' }}>RUNDOWN EVENT</p>
                                </div>
                            </div>
                            <div style={{ padding: '1rem 1rem' }}>
                                <div style={{ display: 'flex', borderBottom: '1px solid #24242480', fontSize: 14, justifyContent: 'space-between' }}>
                                    <div className='mx-2' style={{ display: 'flex', alignItems: 'center', borderBottom: day === 1 && '2px solid #FFFFFF' }}>
                                        <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 1 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(1)}>
                                            31 Jul
                                        </p>
                                    </div>
                                    <div className='mx-2' style={{ display: 'flex', alignItems: 'center', borderBottom: day === 2 && '2px solid #FFFFFF' }}>
                                        <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 2 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(2)}>
                                            01 Aug
                                        </p>
                                    </div>
                                    <div className='mx-2' style={{ display: 'flex', alignItems: 'center', borderBottom: day === 3 && '2px solid #FFFFFF' }}>
                                        <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 3 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(3)}>
                                            02 Aug
                                        </p>
                                    </div>
                                    <div className='mx-2' style={{ display: 'flex', alignItems: 'center', borderBottom: day === 4 && '2px solid #FFFFFF' }}>
                                        <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 4 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(4)}>
                                            03 Aug
                                        </p>
                                    </div>
                                    <div className='mx-2' style={{ display: 'flex', alignItems: 'center', borderBottom: day === 5 && '2px solid #FFFFFF' }}>
                                        <p style={{ marginBottom: 0, padding: '0.5rem 0', cursor: 'pointer', color: day === 5 ? '#FFFFFF' : '#EBEBEB80' }} onClick={() => setDay(5)}>
                                            04 Aug
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='pb-5'>
                                {day === 1 &&
                                    <div style={{ alignItems: 'center', padding: '0 1rem' }}>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    10.00 - 12.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>COMPETITION</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Brewtanding 3</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    13.00 - 20.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>COMPETITION</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Brewtanding 3</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    20.00 - 22.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}></p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>TBA</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {day === 2 &&
                                    <div style={{ alignItems: 'center', padding: '0 1rem' }}>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    10.00 - 14.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>WORKSHOP</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Brewers Cup Judge Calibration in Class</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    14.00 - 18.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>WORKSHOP</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Brewers Cup Judge Calibration on Stage</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    18.00 - 19.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>EXPERIENCE</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Uburkopi Session on stage</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    19.00 - 22.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}></p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>TBA</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {day === 3 &&
                                    <div style={{ alignItems: 'center', padding: '0 1rem' }}>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    10.00 - 18.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>COMPETITION</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Uburkopi Brewers Cup Preliminaries day 1</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    18.00 - 19.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>EXPERIENCE</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Ryan Wibawa Session on stage</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    19.00 - 20.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>WORKSHOP</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Latter art Workshop by Indonesia Coffee Academy</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    20.00 - 22.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}></p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>TBA</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {day === 4 &&
                                    <div style={{ alignItems: 'center', padding: '0 1rem' }}>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    10.00 - 16.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>COMPETITION</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Uburkopi Brewers Cup Preliminiaries Day 2</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    16.00 - 17.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>EXPERIENCE</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Uburkopi session on stage</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    17.00 - 19.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}></p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>TBA</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    19.00 - 20.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>WORKSHOP</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Sensory Workshop by Indonesia Coffee Academy</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    20.00 - 22.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}></p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>TBA</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {day === 5 &&
                                    <div style={{ alignItems: 'center', padding: '0 1rem' }}>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    10.00 - 17.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>COMPETITION</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Uburkopi Brewers Cup Finals</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    17.00 - 19.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>COMPETITION</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Uburkopi Brewers Cup Winner Announcement</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', marginBottom: '3rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    19.00 - 20.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}>EXPERIENCE</p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>Coffe Cupping by Indonesia Coffee Academy</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', height: 100, backgroundColor: '#FFFFFF', alignItems: 'center', padding: '0.5rem', marginRight: '1rem', border: '1px solid #2B2225', boxShadow: '15px 15px #2B2225' }}>
                                            <div className='mt-3'>
                                                <p style={{ backgroundColor: '#2B2225', borderRadius: 18, color: '#FFFFFF', padding: '0.5rem', width: '80%', textAlign: 'center', fontSize: 14 }}>
                                                    20.00 - 22.00 WIB
                                                </p>
                                            </div>
                                            <div style={{ color: '#242424', width: '100%' }}>
                                                <p style={{ margin: 0, fontSize: 14 }}></p>
                                                <p className='font-bold' style={{ margin: 0, fontSize: 14 }}>TBA</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div ref={contact} style={{ padding: '3rem 0', backgroundColor: '#EBEBEB' }}>
                            <div style={{ backgroundColor: '#4C6444', color: '#FFFFFF', padding: '2rem 3rem 2rem 2rem', fontSize: 18 }}>
                                <p className='font-bold' style={{ fontSize: 24 }}>Get In Touch</p>
                                <p className='m-0'>Visit Us:</p>
                                <p className='m-0'>Grand Slipi Tower Lt 10 MNO</p>
                                <p className='m-0'>Jl. S Parman kav 22-24 Palmerah</p>
                                <p style={{ marginBottom: '2rem' }}>Jakarta Barat DKI Jakarta 11480</p>
                                <p className='m-0'>WhatsApp Chat:</p>
                                <p className='m-0'>Mon - Fri from 08.00 to 20.00 WIB</p>
                                <p style={{ marginBottom: '2rem' }}>+6289507518628</p>
                                <p className='m-0'>Email:</p>
                                <p style={{ marginBottom: '2rem' }}>marketing@pengenkopi.com</p>
                                <p className='m-0'>Social Media:</p>
                                <p className='m-0'>@indonesiacafeshow</p>
                            </div>
                            <div className='mb-5' style={{ padding: '0 1rem' }}>
                                <div className='pt-4' style={{ display: 'flex' }}>
                                    <hr className='mx-1' style={{ backgroundColor: '#102821', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                    <p className='font-bold' style={{ fontSize: 24, color: '#2B2225' }}>Contact Us</p>
                                </div>
                                <p className='font-bold' style={{ fontSize: 24, color: '#4C6444' }}>Have Questions? We're Here to Help</p>
                                <p style={{ fontSize: 16, color: '#242424', marginBottom: '2rem' }}>Whether you need assistance with registration, logistics, or general inquiries, our dedicated team is eager to assist you. Reach out to us and let's make your Indonesia Caffe Show experience a seamless one.</p>
                                <div style={{ marginBottom: '2rem' }}>
                                    <input type='text' name='fullName' placeholder='Full Name' className='form-control' style={{ border: '1px solid #4C6444', borderRadius: 12, backgroundColor: '#EBEBEB' }} />
                                </div>
                                <div style={{ marginBottom: '2rem' }}>
                                    <input type='text' name='PhoneNumber' placeholder='Whatsapp Number' className='form-control' style={{ border: '1px solid #4C6444', borderRadius: 12, backgroundColor: '#EBEBEB' }} />
                                </div>
                                <div>
                                    <textarea name='message' placeholder='Tell us what you need' onChange={(e) => setMessage(e.target.value)} className='form-control' rows={10} style={{ border: '1px solid #4C6444', borderRadius: 12, backgroundColor: '#EBEBEB' }} />
                                </div>
                                <Button rel="noopener noreferrer" target='_blank' href={`https://api.whatsapp.com/send/?phone=6289507518628&text=${message}`} className='mt-4 p-2 text-white font-weight-bold' style={{ float: 'right', backgroundColor: '#4C6444', borderRadius: 8, border: '1px solid #4C6444' }}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                        <div className='px-3' ref={galleryRef}>
                            <div className='pt-4 justify-content-center mt-4' style={{ display: 'flex' }}>
                                <hr className='mx-1' style={{ backgroundColor: '#4C6444', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                                <p className='font-bold' style={{ fontSize: 24, color: '#2B2225' }}>GALLERY</p>
                            </div>
                            <div className='mb-4'>
                                <img src={Tenant1} className='mt-4' />
                                <img src={Tenant2} className='mt-4' />
                                <img src={Tenant3} className='mt-4' />
                            </div>
                            <div className='text-center mb-4'>
                                <span onClick={() => setShowGallery(true)} className='font-bold' style={{ color: '#2B2225', fontSize: 18 }}>View More</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <div className='pt-4 justify-content-center mt-4' style={{ display: 'flex' }}>
                            <hr className='mx-1' style={{ backgroundColor: '#4C6444', width: 10, height: 40, borderRadius: 10, marginTop: 0 }} />
                            <p className='font-bold' style={{ fontSize: 24, color: '#2B2225' }}>GALLERY</p>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <hr className='w-100' style={{ border: '2px solid #242424', marginTop: '1.5rem' }} />
                            <div className='d-flex' style={{ backgroundColor: '#242424', color: '#FFFFFF', width: 'fit-content', borderRadius: 16, position: 'absolute', fontSize: 14 }}>
                                <p className='ml-2 my-2 font-bold' style={{ backgroundColor: gallery === 'all' && '#4C6444', borderRadius: 8, padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={() => setGallery('all')}>All Gallery</p>
                                <p className='my-2 font-bold' style={{ backgroundColor: gallery === 'workshop' && '#4C6444', borderRadius: 8, padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={() => setGallery('workshop')}>Workshop</p>
                                <p className='mr-2 my-2 font-bold' style={{ backgroundColor: gallery === 'tenant' && '#4C6444', borderRadius: 8, padding: '0.5rem 1rem', cursor: 'pointer' }} onClick={() => setGallery('tenant')}>Tenant</p>
                            </div>
                        </div>
                        <div className='px-3 mb-5'>
                            {gallery !== 'workshop' &&
                                <>
                                    <img src={Tenant1} className='mt-4' onClick={() => handleZoomImage(Tenant1)} />
                                    <img src={Tenant2} className='mt-4' onClick={() => handleZoomImage(Tenant2)} />
                                    <img src={Tenant3} className='mt-4' onClick={() => handleZoomImage(Tenant3)} />
                                    <img src={Tenant4} className='mt-4' onClick={() => handleZoomImage(Tenant4)} />
                                    <img src={Tenant5} className='mt-4' onClick={() => handleZoomImage(Tenant5)} />
                                    <img src={Tenant7} className='mt-4' onClick={() => handleZoomImage(Tenant7)} />
                                    <img src={Tenant8} className='mt-4' onClick={() => handleZoomImage(Tenant8)} />
                                    <img src={Tenant9} className='mt-4' onClick={() => handleZoomImage(Tenant9)} />
                                    <img src={Tenant10} className='mt-4' onClick={() => handleZoomImage(Tenant10)} />
                                    <img src={Tenant11} className='mt-4' onClick={() => handleZoomImage(Tenant11)} />
                                    <img src={Tenant12} className='mt-4' onClick={() => handleZoomImage(Tenant12)} />
                                    <img src={Tenant13} className='mt-4' onClick={() => handleZoomImage(Tenant13)} />
                                    <img src={Tenant14} className='mt-4' onClick={() => handleZoomImage(Tenant14)} />
                                    <img src={Tenant15} className='mt-4' onClick={() => handleZoomImage(Tenant15)} />
                                    <img src={Tenant16} className='mt-4' onClick={() => handleZoomImage(Tenant16)} />
                                    <img src={Tenant17} className='mt-4' onClick={() => handleZoomImage(Tenant17)} />
                                    <img src={Tenant18} className='mt-4' onClick={() => handleZoomImage(Tenant18)} />
                                    <img src={Tenant19} className='mt-4' onClick={() => handleZoomImage(Tenant19)} />
                                    <img src={Tenant21} className='mt-4' onClick={() => handleZoomImage(Tenant21)} />
                                    <img src={Tenant22} className='mt-4' onClick={() => handleZoomImage(Tenant22)} />
                                    <img src={Tenant23} className='mt-4' onClick={() => handleZoomImage(Tenant23)} />
                                </>
                            }
                            {gallery !== 'tenant' &&
                                <>
                                    <img src={Workshop1} className='mt-4' onClick={() => handleZoomImage(Workshop1)} />
                                    <img src={Workshop2} className='mt-4' onClick={() => handleZoomImage(Workshop2)} />
                                    <img src={Workshop3} className='mt-4' onClick={() => handleZoomImage(Workshop3)} />
                                    <img src={Workshop4} className='mt-4' onClick={() => handleZoomImage(Workshop4)} />
                                    <img src={Workshop5} className='mt-4' onClick={() => handleZoomImage(Workshop5)} />
                                    <img src={Workshop6} className='mt-4' onClick={() => handleZoomImage(Workshop6)} />
                                    <img src={Workshop7} className='mt-4' onClick={() => handleZoomImage(Workshop7)} />
                                    <img src={Workshop8} className='mt-4' onClick={() => handleZoomImage(Workshop8)} />
                                    <img src={Workshop9} className='mt-4' onClick={() => handleZoomImage(Workshop9)} />
                                    <img src={Workshop10} className='mt-4' onClick={() => handleZoomImage(Workshop10)} />
                                    <img src={Workshop11} className='mt-4' onClick={() => handleZoomImage(Workshop11)} />
                                    <img src={Workshop12} className='mt-4' onClick={() => handleZoomImage(Workshop12)} />
                                </>
                            }
                        </div>
                        <Modal width={1000} centered open={openModal} footer={false} closable={false}>
                            <MdClose onClick={() => setOpenModal(false)} style={{ backgroundColor: '#EBEBEB', position: 'absolute', width: 42, height: 42, borderRadius: 50, margin: '-70px 0 0 0', cursor: 'pointer', padding: 10 }} />
                            <img src={zoomImage} alt='gallery' />
                        </Modal>
                    </div>
                )}
                <div style={{ backgroundColor: '#CBBA9D', textAlign: 'center' }}>
                    <div style={{ padding: '4rem 0 2rem 0' }}>
                        <img src={Logo} />
                    </div>
                    <p className='font-bold'>In Collaboration With:</p>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding: '2rem 0 4rem 0' }}>
                        <div className='px-2'>
                            <img src={PengenKopiLogoMobile} />
                        </div>
                        <div className='px-2'>
                            <img src={SCAILogoMobile} />
                        </div>
                        <div className='px-2'>
                            <img src={PorosKopiLogoMobile} />
                        </div>
                        <div className='px-2'>
                            <img src={EmpoLogoMobile} />
                        </div>
                    </div>
                </div>
                <div className='text-center' style={{ backgroundColor: '#EBEBEB', padding: '1rem 1rem' }}>
                    <div>
                        © 2023 PengenKopi All Rights Reserved
                    </div>
                </div>
            </>
        } />
    </div>
  )
}

export default Home