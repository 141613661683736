import React, { useState } from 'react';
import Logo from '../Assets/logo.png';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { SlClose } from 'react-icons/sl';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const Navbar = (props) => {
    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions = [
        {
            label: "HOME",
            value: "home"
        },
        {
            label: "WHAT'S ON",
            value: "whatsOn"
        },
        {
            label: "WHO'S THERE",
            value: "whoThere"
        },
        {
            label: "GETTING HERE",
            value: "gettingHere"
        },
        {
            label: "RUNDOWN EVENT",
            value: "rundown"
        },
        {
            label: "GALLERY",
            value: "gallery"
        },
        {
            label: "CONTACT US",
            value: "contact"
        }
    ]

  return (
    <nav>
        <div className='nav-logo-container'>
            <img src={Logo} alt='' />
        </div>
        <div className='navbar-links-container'>
            <span onClick={() => props.handleClick('home')} style={{ cursor: 'pointer' }}>HOME</span>
            <span onClick={() => props.handleClick('whatsOn')} style={{ cursor: 'pointer' }}>WHAT'S ON</span>
            <span onClick={() => props.handleClick('whoThere')} style={{ cursor: 'pointer' }}>WHO'S THERE</span>
            <span onClick={() => props.handleClick('gettingHere')} style={{ cursor: 'pointer' }}>GETTING HERE</span>
            <span onClick={() => props.handleClick('rundown')} style={{ cursor: 'pointer' }}>RUNDOWN EVENT</span>
            <span onClick={() => props.handleClick('gallery')} style={{ cursor: 'pointer' }}>GALLERY</span>
            <span onClick={() => props.handleClick('contact')} style={{ cursor: 'pointer' }}>CONTACT US</span>
        </div>
        <div></div>
        <div className='navbar-menu-container'>
            <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
        <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor='top' hideBackdrop='false'>
            <Box
                role="presentation"
                className="border"
            >
                <div className='top-menu'>
                    <div className='logo-container'>
                        <img src={Logo} alt='' />
                    </div>
                    <div className='navbar-menu-container'>
                        <SlClose onClick={() => setOpenMenu(false)} />
                    </div>
                </div>
                <List>
                    {menuOptions.map((item, index) => (
                        <ListItem key={index} disablePadding onClick={() => props.handleClick(item.value)}>
                            <ListItemButton>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    </nav>
  )
}

export default Navbar